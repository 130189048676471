import React from 'react'

//import components
import Project from '../components/Project'
import Padding from '../components/Padding'
import FullWidthImage from '../components/FullWidthImage'
import NextSections from '../components/NextSections'
import FullView from '../components/CHICFullView'
import Options from '../components/Options'
import GithubLink from '../components/GithubLink'

// import images
import mockup from '../images/make-me-chic-email/mockup.jpg'
import desktop from '../images/make-me-chic-email/email_option2.jpg'
import mobile from '../images/make-me-chic-email/email_option2_mobile.jpg'
import logo from '../images/make-me-chic-email/logo.svg'
import supportImage from '../images/make-me-chic-email/makemechic_photo.jpg'
import colors from '../images/make-me-chic-email/colors.svg'
import optionOne from '../images/make-me-chic-email/email_option1.jpg'
import optionTwo from '../images/make-me-chic-email/email_option2.jpg'
import optionThree from '../images/make-me-chic-email/email_option3.jpg'

export default () => (
    <Project
        title={'make me chic email'}
            date={'2019'}
            position={'Personal Project'}
            employer={''}
            brief={[
                'Make Me Chic is a retail store, specializing in women’s clothing. As part of a design prompt, I was given photography and copy and asked to create an email blast that was in line with the Make Me Chic brand.',
                'Inspiration was taken from the company website as well as outside sources to create an email that felt trendy, youthful and feminine. Effective email techniques were used in the form of a modular layout, with the most import information placed above the scrolling fold, web-friendly fonts and an appropriate image-to-text ratio.',
                'The design was then coded using industry-standard HTML tables and inline CSS. Responsiveness was also incorporated with media queries to ensure the email displayed effectively on mobile.'
            ]}
            skills={[
                'XD',
                'Illustrator',
                'Branding',
                'HTML',
                'CSS'
            ]}
    >
        <FullWidthImage src={mockup} alt={'Person holding computer and phone'} />
        <FullView 
            desktop={desktop}
            mobile={mobile}
            backgroundColor={'#EEEEEE'}
            codeLink={'https://sophievakalis.github.io/make-me-chic-email/'}
            color={'#E33097'}
        />
        <Padding />
        <Options 
            copy={[
                'Whenever I am creating initial designs for a client, I like to present them with three different options/directions to choose from. This helps to cut down on revisions and allows for more feedback. As previously mentioned, I was only given a color palette, photography and copy to work with. Therefore, I had to infer what the tone of the email should be through context clues in the photography and on their website.',
                'The first option is classic and refined. Serif type is used to create sophistication and delicate borders are incorporated, representing a feminine touch. The second option opposes the first, aiming for a bolder approach. Capitalized bold serifs create a fun and young tone while the use of a photo cutout on their pink background creates an impactful pop. The third option acts as a middle-ground between the first two. A color overlay is incorporated into the header along with serif text.'
            ]}
            logo={logo}
            colors={colors}
            image={supportImage}
            options={[
                {
                    selected: false,
                    src: optionOne
                },
                {
                    selected: true,
                    src: optionTwo
                },
                {
                    selected: false,
                    src: optionThree
                }
            ]}
        />
        <Padding size={'s'}/>
        <GithubLink background={true}/>
        <Padding size={'s'}/>
        <NextSections
            previous={'mcv-web-rebrand'}
            next={'social-media-infographics'}
        />
    </Project>
)