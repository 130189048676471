import React from 'react'

//import styles
import styles from './Options.module.css'

//import components
import Padding from './Padding'
import Line from './Line'

//import images
import check from '../images/make-me-chic-email/check.svg'

export default ({copy, logo, colors, image, options}) => {
    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.copy}>
                    <Line color={'black'}/>
                    <h2>OPTIONS</h2>
                    {copy.map((para) => <p key={`options ${para.split(' ')[0]}`}>{para}</p>)}
                </div>
                <div className={styles.images}>
                    <div className={styles.logo}>
                        <img src={logo} alt={'make me chic logo'} />
                    </div>
                    <div className={styles.colors}>
                        <img src={colors} alt={'brand colors'} />
                    </div>
                    <img src={image} alt={'supporting'} />
                </div>
            </div>
            <Padding size={'m'} />
            <div className={styles.options}>
                {options.map((option, index) => 
                    <div className={styles.option} key={'option'+index}>
                        <div className={styles.title}>
                            <p>Option {index + 1}</p>
                            {option.selected ? <img src={check} alt={'selected'} />: null}
                        </div>
                        <img src={option.src} alt={`Option ${index+1}`} />
                    </div>
                )}
            </div>
        </div>
    )
}